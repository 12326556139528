import { IDropdownOption, Spinner } from 'office-ui-fabric-react';
import * as React from 'react';

import LabForm from 'app/components/Forms/LabForm/LabForm';
import LogisticsForm from 'app/components/Forms/LogisticsForm/LogisticsForm';
import DropDownExtension from 'app/components/DropDown/DropDown';
import Message from 'app/components/Message/Message';
import { RequestType } from 'app/models/Constants/enums';
import { IUser, IUserManager } from 'app/models/IUser';

interface IRequestProps {
    currentUser: IUser;
    currentUserManager: IUserManager;
    refreshNav: (activeComponent: string) => void;
}

interface IRequestState {
    requestTypeOptions: Array<string>;
    requestTypeSelected: string;
    messageType: string;
    isLoading: boolean;
    workItemId: string;
    requestDateTime: Date;
    formSubmitted: boolean;
}

const initState: IRequestState = {
    requestTypeOptions: Object.values(RequestType),
    requestTypeSelected: undefined,
    messageType: undefined,
    isLoading: false,
    workItemId: undefined,
    requestDateTime: undefined,
    formSubmitted: false
};

export default class CreateRequest extends React.Component<IRequestProps, IRequestState> {
    constructor(props: IRequestProps) {
        super(props);
        this.props.refreshNav('CreateRequest');
        this.state = initState;
    }

    public render() {
        return (
            <div>
                <h1>Devices Logistics Request Tool has moved to <a href="https://aka.ms/LLO">https://aka.ms/LLO</a></h1>
                <body>
                    <br/>This tool has been modernized utilizing the latest technologies, while preserving its core functionalities and is available on <a href="https://aka.ms/LLO">https://aka.ms/LLO</a>
                    <br/>The current application <a href="https://llo.westus2.cloudapp.azure.com">https://llo.westus2.cloudapp.azure.com</a> will be permanently deleted on 04/11/2025 and will no longer be available. 
                    <br/>If you use your own bookmark, please update it to the new URL <a href="https://aka.ms/LLO">https://aka.ms/LLO</a>
                    <br/>For any issues accessing the new tool or for any questions, please reach out to &quot;WDSLogistics Team&quot; <a href="mailto:WDSLogistics@microsoft.com">WDSLogistics@microsoft.com</a>
                </body>
            </div>
        );
    }
}
