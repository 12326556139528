import * as React from 'react';
import { IUser } from 'app/models/IUser';
import { Spinner, Fabric, Toggle, TextField, Link, PrimaryButton } from 'office-ui-fabric-react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { OdinService } from 'app/utils/odinService';
import { refreshTokens } from 'app/utils/httpService';
import { IRequestDto, IRequestRelation, IFileResponseMap } from 'app/models/IRequestDto';
import moment from 'moment';
import ViewLogisticsRequest from 'app/components/AllRequests/ViewRequest/ViewLogisticsRequest';
import ViewLabRequest from 'app/components/AllRequests/ViewRequest/ViewLabRequest';
import { abbreviateRequest } from 'app/utils/helperFunctions';
import { AppInsightsService } from 'app/utils/appInsights';

interface IAllRequestsState {
    columns: IColumn[];
    allMyRequests: IRequestDto[];
    allRequestAttachments: IRequestRelation[];
    filteredRequests: IRequestDto[];
    isLoading: boolean;
    isViewingRequest: boolean;
    viewRequestSelected: IRequestDto;
    showClosed: boolean;
}

interface IAllRequestsProps {
    currentUser: IUser;
    refreshNav: (string) => void;
}


export default class AllRequests extends React.Component<IAllRequestsProps, IAllRequestsState> {
    _isMounted = false;

    constructor(props: IAllRequestsProps) {
        super(props);

        this.props.refreshNav('AllRequests');
        this.state = {
            allMyRequests: [],
            allRequestAttachments: [],
            filteredRequests: [],
            columns: [],
            isLoading: false,
            isViewingRequest: false,
            viewRequestSelected: undefined,
            showClosed: false
        };
    }

    public render() {
        return (
            <div>
                <h1>Devices Logistics Request Tool has moved to <a href="https://aka.ms/LLO">https://aka.ms/LLO</a></h1>
                <body>
                    <br/>This tool has been modernized utilizing the latest technologies, while preserving its core functionalities and is available on <a href="https://aka.ms/LLO">https://aka.ms/LLO</a>
                    <br/>The current application <a href="https://llo.westus2.cloudapp.azure.com">https://llo.westus2.cloudapp.azure.com</a> will be permanently deleted on 04/11/2025 and will no longer be available. 
                    <br/>If you use your own bookmark, please update it to the new URL <a href="https://aka.ms/LLO">https://aka.ms/LLO</a>
                    <br/>For any issues accessing the new tool or for any questions, please reach out to &quot;WDSLogistics Team&quot; <a href="mailto:WDSLogistics@microsoft.com">WDSLogistics@microsoft.com</a>
                </body>
            </div>
        );
    }

}